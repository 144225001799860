import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GridStack } from 'gridstack';
import 'gridstack/dist/h5/gridstack-dd-native';
import shortid from 'shortid';
import Global from '../../store/global';
import Firestore from '../../store/firestore';
import Modal from '../Modal';
import Loader from '../Loader';
import CanvasEditor from '../CanvasEditor';
import CodeEditor from '../CodeEditor';
import TextEditor from '../TextEditor';
import './index.scss';

const LOG_MODULE = 'Component:Collaborator';

interface ICollaboratorInnerProps {
    archived: boolean;
    params: any;
    navigate: CallableFunction;
    onLoad: CallableFunction;
}

interface ICollaborateInnerState {
    isLoaded: boolean;
}

class CollaboratorInner extends React.Component<ICollaboratorInnerProps, ICollaborateInnerState> {
    private _canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef<HTMLCanvasElement>();

    private _projectCode = '';

    private _projectPath = '';

    private _document: any;

    private _unSubscribeFirestore: CallableFunction | null = null;

    constructor(props: ICollaboratorInnerProps) {
        super(props);
        this.state = {
            isLoaded: false,
        };

        const { params, archived } = this.props;

        if (!archived) {
            // TODO: Should check the IDs to have exact 3 digits each
            this._projectCode = `${params.projectId}-${params.projectSubId}`;
        } else {
            this._projectCode = params.archiveId;
        }
    }

    public componentDidMount() {
        Firestore.queryDocument(
            'session',
            ['where', 'id', '==', `${this._projectCode}`]
        ).then((docList: Array<any> | null): void => {
            if (!docList || docList.length !== 1 || !docList[0].path || !docList[0].data) {
                this._onSessionLoadError();
            } else {
                const { archived } = this.props;
                const document = docList[0];
                this._projectPath = document.path;
                if (document.data.components) {
                    this._document = document.data;
                    this._onLoadComplete();
                } else if (archived) {
                    this._onSessionLoadError();
                } else {
                    this._document = {
                        ...document.data,
                        alignment: 'grid', // grid / random
                        components: [{
                            id: `text-editor-${shortid.generate()}`,
                            type: 'text-editor',
                            x: 0,
                            y: 0,
                            w: 6,
                            h: 6,
                            locked: 'true',
                            'no-move': 'true',
                        }, {
                            id: `code-editor-${shortid.generate()}`,
                            type: 'code-editor',
                            x: 6,
                            y: 0,
                            w: 6,
                            h: 6,
                            locked: 'true',
                            'no-move': 'true',
                        }, {
                            id: `canvas-editor-${shortid.generate()}`,
                            type: 'canvas-editor',
                            x: 0,
                            y: 6,
                            w: 12,
                            h: 6,
                            locked: 'true',
                            'no-move': 'true',
                        }],
                    };
                    Firestore.setDocument(
                        this._projectPath,
                        this._document
                    ).then(() => {
                        console.info(LOG_MODULE, 'Success setting document');
                        this._onLoadComplete();
                    }).catch((e) => {
                        console.error(LOG_MODULE, 'Error setting document:', e);
                    });
                }
            }
        }).catch((e) => {
            console.error(LOG_MODULE, 'Error updating data:', e);
        });

        const canvas = this._canvasRef.current;
        if (!canvas) {
            return;
        }

        // resize the canvas to fill browser window dynamically
        window.addEventListener('resize', this._resizeCanvas, false);
        this._resizeCanvas();
    }

    public componentDidUpdate() {
        const { isLoaded } = this.state;
        if (isLoaded) {
            // Grid initialization should only be done after .grid-stack is present in the DOM
            GridStack.init({
                disableOneColumnMode: true,
                float: false,
            });
        }
    }

    public componentWillUnmount() {
        if (this._unSubscribeFirestore) {
            this._unSubscribeFirestore();
        }
        window.removeEventListener('resize', this._resizeCanvas);
    }

    private _onSessionLoadError = () => {
        console.error(LOG_MODULE, 'Session doesn\'t exist');
        Modal.show(
            (
                <div className="session-invalid">
                    <div className="box">You seem to be lost!</div>
                    <figure className="image lost-image">
                        <img src="/resources/img/illustrations/lost.svg" alt="" />
                    </figure>
                    <button
                        type="button"
                        className="button is-primary"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                            Modal.hide();
                            const { navigate } = this.props;
                            navigate(Global.routeTo('/'));
                        }}>
                        Take me Home
                    </button>
                </div>
            ),
            { allowClose: false, backdropOpacity: 0.4 }
        );
    };

    private _onSessionArchive = (archiveId: string) => {
        console.info(LOG_MODULE, 'Session has been archived');
        const { navigate } = this.props;
        navigate(Global.routeTo(`/archive/${archiveId}`));
        Modal.show(
            (
                <div className="session-archived">
                    <div className="box">The session has been archived!</div>
                    <figure className="image archive-image">
                        <img src="/resources/img/illustrations/archive.svg" alt="" />
                    </figure>
                </div>
            ),
            { allowClose: true, backdropOpacity: 0.8 }
        );
    };

    private _onLoadComplete = () => {
        if (this.state.isLoaded) {
            return;
        }

        const { archived } = this.props;
        if (!archived) {
            const unSubscribe = Firestore.subscribeDocument(
                this._projectPath,
                (data: any): void => {
                    if (data.archivedAt) {
                        this._onSessionArchive(data.id);
                    }
                }
            );
            if (!unSubscribe) {
                console.error(LOG_MODULE, 'Error in subscription');
                return;
            }
            this._unSubscribeFirestore = unSubscribe || this._unSubscribeFirestore;
        }

        const { onLoad } = this.props;
        this.setState({
            isLoaded: true,
        }, () => onLoad(this._projectPath));
    };

    private _resizeCanvas = () => {
        const canvas = this._canvasRef.current;
        if (!canvas) {
            return;
        }

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
    };

    private _getComponentGrid() {
        const { archived } = this.props;
        return (
            <div className="grid-stack">
                {/* <canvas ref={this._canvasRef} /> */}
                {
                    !!this._document && this._document.components.map((c: any) => {
                        let Module = null;
                        if (c.type === 'text-editor') {
                            Module = TextEditor;
                        } else if (c.type === 'code-editor') {
                            Module = CodeEditor;
                        } else if (c.type === 'canvas-editor') {
                            Module = CanvasEditor;
                        }
                        if (!Module) {
                            return null;
                        }
                        const grid = {
                            'gs-x': c.x || 0,
                            'gs-y': c.y || 0,
                            'gs-w': c.w || 0,
                            'gs-h': c.h || 0,
                            'gs-locked': 'true',
                            'gs-no-move': 'true',
                        };
                        return (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <div key={c.id} className="grid-stack-item" {...grid}>
                                <div className="grid-stack-item-content">
                                    <Module
                                        project={this._projectPath}
                                        id={c.id}
                                        archived={archived} />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    public render() {
        if (!this._projectCode) {
            const { navigate } = this.props;
            navigate(Global.routeTo('/'));
        }

        const { isLoaded } = this.state;
        return (
            <div className="collaborator-wrapper">
                {
                    (!isLoaded) ? (
                        <Loader />
                    ) : (
                        <section className="section is-medium collaborator-section">
                            <div className="container is-fluid collaborator-content">
                                {this._getComponentGrid()}
                            </div>
                            <div className="divider is-centered is-spacer" />
                        </section>
                    )
                }
            </div>
        );
    }
}

function Collaborator(props: any) {
    const params = useParams();
    const navigate = useNavigate();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <CollaboratorInner params={params} navigate={navigate} {...props} />;
}
export default Collaborator;
