import React from 'react';
import './index.scss';

const LOG_MODULE = 'Component:Loader';

class Loader extends React.Component {
    render() {
        return (
            <div className="loader-wrapper">
                <div id="page-loader" className="page-loader is-left-to-right is-theme is-active" />
                <div id="infra-loader" className="infra-loader is-active" />
            </div>
        );
    }
}

export default Loader;
