import React from 'react';
import paper from 'paper';
import {
    Color, Path, Point, Tool,
} from 'paper/dist/paper-core';
import shortid from 'shortid';
import Firestore from '../../store/firestore';
import './index.scss';

const LOG_MODULE = 'Component:CanvasEditor';

interface ICanvasEditorProps {
    id: string;
    project: string;
    archived: boolean;
}

class CanvasEditor extends React.Component<ICanvasEditorProps> {
    private _canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef<HTMLCanvasElement>();

    private _docPath = '';

    private _pathMap: any = {};

    private _unSubscribeFirestore: CallableFunction | null = null;

    constructor(props: ICanvasEditorProps) {
        super(props);

        const { id, project } = this.props;
        this._docPath = `${project}/${id}/root`;
    }

    public componentDidMount() {
        // Get a reference to the canvas object
        const canvas = this._canvasRef.current;

        if (!canvas) {
            return;
        }
        // Create an empty project and a view for the canvas:
        paper.setup(canvas);

        // Create a Paper.js Path to draw a line into it:
        let path = new paper.Path();

        // Create a simple drawing tool:
        const tool = new paper.Tool();

        // tool.minDistance = 10;
        // tool.maxDistance = 45;

        // Define a mousedown and mousedrag handler
        tool.onMouseDown = (event: { point: paper.Point | paper.Segment | number[]; }) => {
            // If we produced a path before, deselect it:
            if (path) {
                path.selected = false;
            }

            // Create a new path and set its stroke color to black:
            path = new paper.Path({
                segments: [event.point],
                strokeColor: 'blue',
                // Select the path, so we can see its segment points:
                // fullySelected: true
            });

            // @ts-ignore: to differentiate between existing path and in-progress path
            path.state = 'new'; // could be 'new', 'old', 'deleted', etc.

            // @ts-ignore: view.draw(...) is known to exist
            paper.view.draw();

            this._pathMap[shortid.generate()] = path;

            const { archived } = this.props;
            if (!archived) {
                Firestore.setDocument(
                    this._docPath,
                    this._getPathMapToString()
                ).catch((e) => {
                    console.error(LOG_MODULE, 'Error setting document:', e);
                });
            }
        };

        tool.onMouseDrag = (event: { point: paper.Point | paper.Segment | number[]; }) => {
            path.add(event.point);

            // @ts-ignore: view.draw(...) is known to exist
            paper.view.draw();

            const { archived } = this.props;
            if (!archived) {
                Firestore.setDocument(
                    this._docPath,
                    this._getPathMapToString()
                ).catch((e) => {
                    console.error(LOG_MODULE, 'Error setting document:', e);
                });
            }
        };

        // When the mouse is released, we simplify the path:
        tool.onMouseUp = () => {
            // When the mouse is released, simplify it:
            path.smooth({
                type: 'continuous',
            });

            // When the mouse is released, simplify it:
            path.simplify(10);

            // Select the path, so we can see its segments:
            // path.fullySelected = true;

            // @ts-ignore: view.draw(...) is known to exist
            paper.view.draw();

            // @ts-ignore: to differentiate between existing path and in-progress path
            path.state = 'old'; // could be 'new', 'old', 'deleted', etc.

            const { archived } = this.props;
            if (!archived) {
                Firestore.setDocument(
                    this._docPath,
                    this._getPathMapToString()
                ).catch((e) => {
                    console.error(LOG_MODULE, 'Error setting document:', e);
                });
            }
        };

        // Draw the view now:
        // @ts-ignore: view.draw(...) is known to exist
        paper.view.draw();

        const { archived } = this.props;
        if (!archived) {
            const unSubscribe = Firestore.subscribeDocument(
                this._docPath,
                (data: any, isLocal: boolean): void => {
                    if (!data || isLocal || !this._canvasRef.current) {
                        return;
                    }
                    try {
                        const pathMap = JSON.parse(data);
                        Object.keys(pathMap).forEach((key) => {
                            if (!this._pathMap[key]) {
                                const p = new paper.Path({
                                    strokeColor: 'red',
                                });
                                p.importJSON(pathMap[key].path);
                                // @ts-ignore: to set the state property
                                p.state = pathMap[key].state;
                                this._pathMap[key] = p;
                            } else if (this._pathMap[key].state === 'new') {
                                this._pathMap[key].importJSON(pathMap[key].path);
                                this._pathMap[key].state = pathMap[key].state;
                            }
                        });
                        // @ts-ignore: view.draw(...) is known to exist
                        paper.view.draw();
                    } catch (e) {
                        console.error(LOG_MODULE, 'Exception in setContents from subscribed DB:', e);
                    }
                }
            );
            if (!unSubscribe) {
                console.error(LOG_MODULE, 'Error in subscription');
            }
            this._unSubscribeFirestore = unSubscribe || this._unSubscribeFirestore;
        }

        Firestore.getDocument(this._docPath).then((data: any): void => {
            if (!data || !this._canvasRef.current) {
                return;
            }
            try {
                const pathMap = JSON.parse(data);
                Object.keys(pathMap).forEach((key) => {
                    if (!this._pathMap[key]) {
                        const p = new paper.Path({
                            strokeColor: 'green',
                        });
                        p.importJSON(pathMap[key].path);
                        // @ts-ignore: to set the state property
                        p.state = pathMap[key].state;
                        this._pathMap[key] = p;
                    } else if (this._pathMap[key].state === 'new') {
                        this._pathMap[key].importJSON(pathMap[key].path);
                        this._pathMap[key].state = pathMap[key].state;
                    }
                });
                // @ts-ignore: view.draw(...) is known to exist
                paper.view.draw();
                // Force canvas to reevaluate and fix its size
                window.dispatchEvent(new Event('resize'));
            } catch (e) {
                console.error(LOG_MODULE, 'Exception in setContents from init DB:', e);
            }
        }).catch((e) => {
            console.error(LOG_MODULE, 'Error updating data:', e);
        });

        // Force canvas to reevaluate and fix its size
        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2000);
    }

    public componentWillUnmount() {
        if (this._unSubscribeFirestore) {
            this._unSubscribeFirestore();
        }
    }

    private _getPathMapToString = () => {
        const obj: any = {};
        Object.keys(this._pathMap).forEach((key) => {
            obj[key] = {
                path: this._pathMap[key].exportJSON({
                    asString: true,
                    precision: 5,
                }),
                state: this._pathMap[key].state,
            };
        });
        return JSON.stringify(obj);
    };

    public render() {
        return (
            <div className="canvas-editor-wrapper">
                <div id="canvas-editor">
                    {/*
                     // @ts-ignore: To allow resize prop in canvas component */}
                    <canvas ref={this._canvasRef} resize="true" />
                </div>
            </div>
        );
    }
}

export default CanvasEditor;
