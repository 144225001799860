import React from 'react';
import Global from '../../store/global';
import './index.scss';

const LOG_MODULE = 'Component:Footer';

class Footer extends React.Component {
    public render() {
        return (
            <div className="footer-wrapper">
                <footer className="app-footer">
                    <div className="container">
                        <div className="footer-logo">
                            <a href={Global.routeTo('/')}>
                                <img className="rotating" src="/logo.png" alt="" />
                                <div className="brand-name">VShare</div>
                                <div className="brand-subtitle">Online Collaborator</div>
                            </a>
                        </div>
                        <p className="copyright">© 2022 | VShare. All Rights Reserved</p>
                        <br />
                        <p className="coded-by">Designed and Created with ❤️</p>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
