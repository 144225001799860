import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom';
import Collaborator from '../Collaborator';
import Footer from '../Footer';
import Home from '../Home';
import NavBar from '../NavBar';
import './index.scss';

class App extends React.Component {
    private _projectPath = '';

    private _onCollaboratorLoad = (projectPath: string) => {
        this._projectPath = projectPath;
        this.forceUpdate();
    };

    public render() {
        return (
            <div className="app-wrapper">
                <BrowserRouter>
                    <div className="app-content-wrapper">
                        <section className="content">
                            {/* TODO(shmmsra): Need to pass  archive state to the Nav bar */}
                            <Routes>
                                <Route
                                    path="/archive/:archiveId"
                                    element={<NavBar project={this._projectPath} archived />} />
                                <Route
                                    path="*"
                                    element={(
                                        <NavBar project={this._projectPath} archived={false} />
                                    )} />
                            </Routes>
                            <Routes>
                                <Route path="/index.html" element={<Home />} />
                                <Route path="/">
                                    <Route index element={<Home />} />
                                    <Route
                                        path=":projectId/:projectSubId"
                                        element={(
                                            <Collaborator
                                                key={0} // Key needs to be change to trigger unmount
                                                onLoad={this._onCollaboratorLoad}
                                                archived={false} />
                                        )} />
                                    <Route
                                        path="/archive/:archiveId"
                                        element={(
                                            <Collaborator
                                                key={1} // Key needs to be change to trigger unmount
                                                onLoad={this._onCollaboratorLoad}
                                                archived />
                                        )} />
                                    <Route path="*" element={<Home />} />
                                </Route>
                            </Routes>
                        </section>
                    </div>
                    <Footer />
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
