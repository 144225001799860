import React from 'react';
import Quill from 'quill';
import Firestore from '../../store/firestore';
import 'quill/dist/quill.snow.css';
import './index.scss';

const LOG_MODULE = 'Component:TextEditor';

interface ITextEditorProps {
    id: string;
    project: string;
    archived: boolean;
}

class TextEditor extends React.Component<ITextEditorProps> {
    private _editorRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private _docPath = '';

    private _editorConfig = {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote', 'code-block'],

                [{ list: 'ordered' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ align: [] }],

                ['clean'], // remove formatting button
            ],
        },
        placeholder: 'Type your text here...',
        theme: 'snow', // or 'bubble'
    };

    // @ts-ignore: _quill would mandatorily be set in componentDidMount(...)
    private _quill: Quill = null;

    private _unSubscribeFirestore: CallableFunction | null = null;

    constructor(props: ITextEditorProps) {
        super(props);

        const { id, project } = this.props;
        this._docPath = `${project}/${id}/root`;
    }

    public componentDidMount() {
        this._quill = new Quill(this._editorRef.current || '', this._editorConfig);

        const { archived } = this.props;
        if (!archived) {
            const unSubscribe = Firestore.subscribeDocument(
                this._docPath,
                (data: any, isLocal: boolean): void => {
                    if (!data || isLocal) {
                        return;
                    }
                    try {
                        const delta = JSON.parse(data);
                        this._quill.setContents(delta, 'silent');
                    } catch (e) {
                        console.error(LOG_MODULE, 'Exception in setContents from subscribed DB:', e);
                    }
                }
            );
            if (!unSubscribe) {
                console.error(LOG_MODULE, 'Error in subscription');
            }
            this._unSubscribeFirestore = unSubscribe || this._unSubscribeFirestore;
        }

        Firestore.getDocument(this._docPath).then((data: any): void => {
            if (!data) {
                return;
            }
            try {
                const delta = JSON.parse(data);
                this._quill.setContents(delta, 'silent');
            } catch (e) {
                console.error(LOG_MODULE, 'Exception in setContents from init DB:', e);
            }
        }).catch((e) => {
            console.error(LOG_MODULE, 'Error updating data:', e);
        });

        this._quill.on('text-change', this._onEditorTextChange);
    }

    public componentWillUnmount() {
        this._quill.off('text-change', this._onEditorTextChange);
        if (this._unSubscribeFirestore) {
            this._unSubscribeFirestore();
        }
    }

    private _onEditorTextChange = (delta: any, oldDelta: any, source: any) => {
        if (source !== 'user') {
            return;
        }
        try {
            const { archived } = this.props;
            if (!archived) {
                const doc = JSON.stringify(this._quill.getContents());
                Firestore.setDocument(this._docPath, doc).catch((e) => {
                    console.error(LOG_MODULE, 'Error setting document:', e);
                });
            }
        } catch (e) {
            console.error(LOG_MODULE, 'Exception in setDocument to DB:', e);
        }
    };

    public render() {
        return (
            <div className="text-editor-wrapper">
                <div id="text-editor" ref={this._editorRef} />
            </div>
        );
    }
}

export default TextEditor;
