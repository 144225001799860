class Global {
    private _documentMap: Map<string, object> = new Map();

    public get code(): string {
        const _url = new URLSearchParams(window.location.search);
        return _url.get('code') || '';
    }

    public get debug(): boolean {
        const _url = new URLSearchParams(window.location.search);
        if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') {
            if (_url.get('debug') === 'true') {
                return true;
            }
        }
        return false;
    }

    public setDocument(key: string, value: object): boolean {
        if (!key || !value) {
            return false;
        }
        this._documentMap.set(key, value);
        return true;
    }

    public getDocument(key: string): object {
        if (!key) {
            return {};
        }
        return this._documentMap.get(key) || {};
    }

    public routeTo(path: string): string {
        const _url = new URLSearchParams(window.location.search);
        return `${path}?${_url.toString()}`;
    }
}

export default new Global();
