// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

class Firebase {
    private _app: any;

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    public get config(): object {
        return {
            apiKey: 'AIzaSyBBzid1mW3mpveS7FLQ43-R9EjJ6jKHMj4',
            authDomain: 'area57-7e1ae.firebaseapp.com',
            databaseURL: 'https://area57-7e1ae-default-rtdb.firebaseio.com',
            projectId: 'area57-7e1ae',
            storageBucket: 'area57-7e1ae.appspot.com',
            messagingSenderId: '806398258170',
            appId: '1:806398258170:web:5f91ca2b99fcc7fea05243',
            measurementId: 'G-CBPHNH1Z48',
        };
    }

    public get app(): any {
        return this._app;
    }

    // Initialize Firebase
    constructor() {
        this._app = initializeApp(this.config);
    }
}

export default new Firebase();
