// Import the functions you need from the SDKs you need
import {
    getFirestore,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    collection,
    doc,
    query,
    where,
    connectFirestoreEmulator,
    onSnapshot,
    Timestamp,
} from 'firebase/firestore';
import Global from './global';
import Firebase from './firebase';

const LOG_MODULE = 'Store:Firestore';

class Firestore {
    private _db: any;

    constructor() {
        this._db = getFirestore(Firebase.app);
        if (Global.debug) {
            connectFirestoreEmulator(this._db, 'localhost', 8080);
        }
    }

    public subscribeDocument(path: string, callback: CallableFunction): CallableFunction | null {
        if (!path || !callback) {
            return null;
        }

        const unSubscribeDocument = onSnapshot(doc(this._db, path), (document) => {
            const source = document.metadata.hasPendingWrites ? 'local' : 'server';
            const data = document.data();
            if (data) {
                if (data['@@data@@']) {
                    callback(data['@@data@@'], source !== 'server');
                    return;
                }
                callback(data, source !== 'server');
            }
        });

        return unSubscribeDocument;
    }

    public async getDocument(documentPath: string): Promise<any | null> {
        if (!documentPath) {
            return null;
        }

        try {
            const docRef = doc(this._db, documentPath);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data) {
                    if (data['@@data@@']) {
                        return data['@@data@@'];
                    }
                    return data;
                }
            }
        } catch (e) {
            console.error(LOG_MODULE, 'getDocument:', e);
        }

        return null;
    }

    public async setDocument(documentPath: string, data: any): Promise<boolean> {
        // Empty (''), 0, or similar falsy value may be a valid (excluding `undefined` for now)
        if (!documentPath || data === undefined) {
            return false;
        }

        try {
            const docRef = doc(this._db, documentPath);
            if (typeof data === 'string') {
                await setDoc(docRef, { '@@data@@': data });
            } else if (typeof data === 'object') {
                await setDoc(docRef, data);
            } else {
                await setDoc(docRef, { '@@data@@': JSON.stringify(data) });
            }
        } catch (e) {
            console.error(LOG_MODULE, 'setDocument:', e);
            return false;
        }

        return true;
    }

    public async updateDocument(documentPath: string, data: any): Promise<boolean> {
        // Empty (''), 0, or similar falsy value may be a valid (excluding `undefined` for now)
        if (!documentPath || data === undefined) {
            return false;
        }

        try {
            const docRef = doc(this._db, documentPath);
            if (typeof data === 'string') {
                await updateDoc(docRef, { '@@data@@': data });
            } else if (typeof data === 'object') {
                await updateDoc(docRef, data);
            } else {
                await updateDoc(docRef, { '@@data@@': JSON.stringify(data) });
            }
        } catch (e) {
            console.error(LOG_MODULE, 'setDocument:', e);
            return false;
        }

        return true;
    }

    public async queryDocument(
        collectionPath: string,
        queryObj: Array<string>
    ): Promise<Array<any> | null> {
        if (!collectionPath || !queryObj || !Array.isArray(queryObj) || queryObj.length < 2) {
            return null;
        }

        const collectionRef = collection(this._db, collectionPath);
        const queryMap: any = {
            where,
        };
        const q = query(collectionRef, queryMap[queryObj[0]](...queryObj.slice(1)));
        const querySnapshot = await getDocs(q);
        const documentList: Array<any> = [];
        querySnapshot.forEach((document) => {
            const data = document.data();
            if (data['@@data@@']) {
                documentList.push({
                    path: `${collectionPath}/${document.id}`,
                    data: data['@@data@@'],
                });
            } else {
                documentList.push({
                    path: `${collectionPath}/${document.id}`,
                    data,
                });
            }
        });

        return documentList;
    }

    public getServerTimestamp(): number {
        return Timestamp.now().seconds;
    }
}

export default new Firestore();
