import React from 'react';
import { useNavigate } from 'react-router-dom';
import shortid from 'shortid';
import Firestore from '../../store/firestore';
import Global from '../../store/global';
import './index.scss';

const LOG_MODULE = 'Component:Home';

interface IHomeInnerProps {
    navigate: CallableFunction;
}

interface IHomeInnerState {
    isLoading: boolean;
}

class HomeInner extends React.Component<IHomeInnerProps, IHomeInnerState> {
    constructor(props: IHomeInnerProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    private _generateProject = (iteration = 1): void => {
        if (iteration > 10) {
            console.error(LOG_MODULE, 'Unable to find a valid project code');
            return;
        }

        const projectId = Math.floor((Math.random() * 899) + 100);
        const projectSubId = Math.floor((Math.random() * 899) + 100);

        const projectCode = `${projectId}-${projectSubId}`;
        Firestore.queryDocument(
            'session',
            ['where', 'id', '==', `${projectCode}`]
        ).then((docList: Array<any> | null): void => {
            if (!docList || docList.length < 1) {
                // TODO(shmmsra): Need to avoid any potential conflict in projectPath
                const projectPath = `session/${shortid.generate()}`;
                console.info(
                    LOG_MODULE,
                    'New project to be created with code:',
                    projectCode,
                    ', path:',
                    projectPath
                );
                const document = {
                    id: projectCode,
                    createdAt: Firestore.getServerTimestamp(),
                    userId: null,
                };
                Firestore.setDocument(projectPath, document).then(() => {
                    console.info(LOG_MODULE, 'Success setting document');
                    const { navigate } = this.props;
                    navigate(Global.routeTo(`${projectId}/${projectSubId}`));
                }).catch((e) => {
                    console.error(LOG_MODULE, 'Error setting document:', e);
                });
            } else {
                setTimeout(() => this._generateProject(iteration + 1), 0);
            }
        }).catch((e) => {
            console.error(LOG_MODULE, 'Error in project code validation:', e);
        });
    };

    private _onCollaborateClick = () => {
        if (this.state.isLoading) {
            console.warn(LOG_MODULE, 'Session loading is already in progress');
            return;
        }

        this.setState({
            isLoading: true,
        });
        this._generateProject();
    };

    public render() {
        const { isLoading } = this.state;
        return (
            <div className="home-wrapper">
                <section className="hero is-fullheight is-transparent">
                    <div className="hero-head" />

                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-5 landing-caption">
                                    <h1 className="title is-1 is-light is-semibold is-spaced main-title">
                                        VShare - Write, Code, and Draw with realtime collaboration
                                    </h1>
                                    <h2 className="subtitle is-5 is-light is-thin">
                                        An online collaboration tool with rich-text editor,
                                        code editor and canvas, all at one place.
                                        <br />
                                        More to come...
                                    </h2>
                                    <p className="buttons">
                                        <div
                                            className="button k-button k-primary raised has-gradient is-fat is-bold"
                                            onClick={this._onCollaborateClick}>
                                            <span className="text">Get Started</span>
                                            <span className="front-gradient" />
                                        </div>
                                    </p>
                                </div>
                                <div className="column is-7">
                                    <figure className="image collaborate-image">
                                        <img src="/resources/img/illustrations/collaborate.svg" alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function Home(props: any) {
    const navigate = useNavigate();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <HomeInner navigate={navigate} {...props} />;
}
export default Home;
