import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

interface ModalOptions {
    allowClose?: boolean;
    backdropOpacity?: number;
}
class Modal {
    show(component: any, options: ModalOptions = {}) {
        ReactDOM.render(
            <React.StrictMode>
                <div className="modal-wrapper">
                    <div className="modal is-active">
                        <div
                            className="modal-background"
                            style={options.backdropOpacity ? {
                                backgroundColor: `rgba(10, 10, 10, ${options.backdropOpacity})`,
                            } : undefined} />
                        <div className="modal-content">
                            {component}
                        </div>
                        {
                            options.allowClose ? (
                                <button
                                    type="button"
                                    className="modal-close is-large"
                                    aria-label="close"
                                    onClick={this.hide} />
                            ) : null
                        }
                    </div>
                </div>
            </React.StrictMode>,
            document.getElementById('modal-root')
        );
    }

    hide() {
        ReactDOM.render(
            <div />,
            document.getElementById('modal-root')
        );
    }
}

export default new Modal();
