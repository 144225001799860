import React from 'react';
import shortid from 'shortid';
import Global from '../../store/global';
import Firestore from '../../store/firestore';
import './index.scss';

const LOG_MODULE = 'Component:NavBar';

interface INavBarProps {
    project: string;
    archived: boolean;
}

interface INavBarState {
    isFloating: boolean;
}

class NavBar extends React.Component<INavBarProps, INavBarState> {
    private _navRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private _intersectionObserver: IntersectionObserver | null = null;

    constructor(props: INavBarProps) {
        super(props);
        this.state = {
            isFloating: false,
        };
    }

    public componentDidMount() {
        if (!this._navRef || !this._navRef.current) {
            return;
        }

        this._intersectionObserver = new IntersectionObserver((entries) => {
            const { isFloating } = this.state;
            if (entries[0].isIntersecting && isFloating) {
                this.setState({
                    isFloating: false,
                });
            } else if (!entries[0].isIntersecting && !isFloating) {
                this.setState({
                    isFloating: true,
                });
            }
        }, { threshold: [0.6] });
        this._intersectionObserver.observe(this._navRef.current);
    }

    public componentWillUnmount() {
        if (!this._navRef || !this._navRef.current) {
            return;
        }

        if (!this._intersectionObserver) {
            return;
        }
        this._intersectionObserver.unobserve(this._navRef.current);
    }

    private _onArchiveClick = () => {
        const { project } = this.props;
        if (!project) {
            return;
        }
        console.info(LOG_MODULE, 'archive button clicked');
        Firestore.updateDocument(project, {
            archivedAt: Firestore.getServerTimestamp(),
            id: shortid.generate(),
        });
    };

    public render() {
        const { isFloating } = this.state;
        const { project, archived } = this.props;

        return (
            <div className="navbar-wrapper">
                <nav
                    id="navbar-static"
                    className="navbar"
                    ref={this._navRef} />
                <nav
                    id="navbar-fluid"
                    className={`navbar ${isFloating ? 'is-floating' : ''}`}>
                    <div className="container navbar-container">
                        <div className="navbar-brand">
                            <a href={Global.routeTo('/')} className="navbar-item">
                                <img className="rotating" src="/logo.png" alt="" />
                                <span className="brand-name">VShare</span>
                            </a>
                            <div className="navbar-burger">
                                <div className="menu-toggle">
                                    <span className="icon-box-toggle">
                                        <span className="rotate">
                                            <i className="icon-line-top" />
                                            <i className="icon-line-center" />
                                            <i className="icon-line-bottom" />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="navbar-menu" className="navbar-menu light-menu">
                            <div className="navbar-end">
                                <div className="navbar-item">
                                    { (project && !archived) ? (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                            onClick={this._onArchiveClick}
                                            className="button k-button k-primary raised has-gradient slanted">
                                            <span className="text">Archive</span>
                                            <span className="front-gradient" />
                                        </a>
                                    ) : null }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-padding" />
                </nav>
            </div>
        );
    }
}

export default NavBar;
