import React from 'react';
import CodeMirror, { ReactCodeMirrorRef, ViewUpdate } from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
// eslint-disable-next-line import/no-extraneous-dependencies
import { oneDark } from '@codemirror/theme-one-dark';
import Firestore from '../../store/firestore';
import './index.scss';

const LOG_MODULE = 'Component:CodeEditor';

interface ICodeEditorProps {
    id: string;
    project: string;
    archived: boolean;
}

class CodeEditor extends React.Component<ICodeEditorProps> {
    private _editorRef: React.RefObject<ReactCodeMirrorRef> = React.createRef<ReactCodeMirrorRef>();

    private _docPath = '';

    private _content = '\"Hello, world!\"';

    private _unSubscribeFirestore: CallableFunction | null = null;

    constructor(props: ICodeEditorProps) {
        super(props);

        const { id, project } = this.props;
        this._docPath = `${project}/${id}/root`;
    }

    public componentDidMount() {
        const { archived } = this.props;
        if (!archived) {
            const unSubscribe = Firestore.subscribeDocument(
                this._docPath,
                (data: any, isLocal: boolean): void => {
                    // Empty ('') data could be a valid data, hence an explicit undefined check
                    if (data === undefined || isLocal) {
                        return;
                    }
                    try {
                        this._updateContent(data);
                    } catch (e) {
                        console.error(LOG_MODULE, 'Exception in setContents from subscribed DB:', e);
                    }
                }
            );
            if (!unSubscribe) {
                console.error(LOG_MODULE, 'Error in subscription');
            }
            this._unSubscribeFirestore = unSubscribe || this._unSubscribeFirestore;
        }

        Firestore.getDocument(this._docPath).then((data: any): void => {
            // Empty ('') data could be a valid data, hence an explicit check
            if (!data && data !== '') {
                return;
            }
            try {
                this._updateContent(data);
            } catch (e) {
                console.error(LOG_MODULE, 'Exception in setContents from init DB:', e);
            }
        }).catch((e) => {
            console.error(LOG_MODULE, 'Error updating data:', e);
        });
    }

    public componentWillUnmount() {
        if (this._unSubscribeFirestore) {
            this._unSubscribeFirestore();
        }
    }

    private _updateContent = (data: string): void => {
        if (!this._editorRef.current || !this._editorRef.current.view) {
            return;
        }

        if (this._content === data) {
            return;
        }

        const { view } = this._editorRef.current;
        const transaction = view.state.update({
            changes: {
                from: 0,
                to: view.state.doc.length,
                insert: `${data}`,
            },
        });
        const update = view.state.update(transaction);

        // TODO: view.dispatch(...) would be an async operation,
        // should ideally set the this._content only after the view has been updated
        // but no update sync API seems to be available in CodeMirror v6
        this._content = data;
        view.update([update]);
    };

    private _onEditorCodeChange = (value: string, viewUpdate: ViewUpdate): void => {
        try {
            if (this._content === value) {
                return;
            }
            this._content = value;

            const { archived } = this.props;
            if (!archived) {
                Firestore.setDocument(this._docPath, this._content).catch((e) => {
                    console.error(LOG_MODULE, 'Error setting document:', e);
                });
            }
        } catch (e) {
            console.error(LOG_MODULE, 'Exception in setDocument to DB:', e);
        }
    };

    public render() {
        return (
            <div className="code-editor-wrapper">
                <div id="code-editor">
                    <CodeMirror
                        {...{
                            height: '100%',
                            theme: oneDark,
                            extensions: [javascript({ jsx: true })],
                        }}
                        value={this._content}
                        onChange={this._onEditorCodeChange}
                        ref={this._editorRef} />
                </div>
            </div>
        );
    }
}

export default CodeEditor;
